.admin_panel_window .admin_panel_add_new_product_form input, .admin_panel_window .admin_panel_add_new_product_form .category_select, .admin_panel_window .admin_panel_add_new_product_form label, .admin_panel_window .admin_panel_add_new_product_form textarea {
  border-radius: 5px;
  width: 200px;
  display: flex;
  margin: 10px;
  padding: 3px;
}
.admin_panel_window .admin_panel_add_new_product_form .price_and_unit {
  display: flex;
}
.admin_panel_window .admin_panel_add_new_product_form .price_and_unit input {
  border-radius: 5px 0 0 5px;
  width: 140px;
  display: flex;
  margin: 10px 0 10px 10px;
  padding: 3px;
}
.admin_panel_window .admin_panel_add_new_product_form .price_and_unit .unit_select {
  border-radius: 0 5px 5px 0;
  margin: 10px 0 10px 0;
  width: 60px;
  display: flex;
  padding: 3px;
}
.admin_panel_window .admin_panel_add_new_product_form .admin_panel_add_new_product_button {
  padding: 5px;
  margin: 0 10px;
}/*# sourceMappingURL=newProduct.css.map */