@charset "UTF-8";
@media (max-width: 4320px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  }
  .container .categoryAside {
    border-right: solid 2px #D4D4D4;
    width: 300px;
    padding: 20px;
  }
  .container .categoryAside .category_h2 {
    font-size: 24px;
    line-height: 18px;
    text-align: center;
    padding-top: 20px;
  }
  .container .categoryAside .category_h2 .category_logo {
    margin-right: 9px;
    height: 24px;
    width: 24px;
  }
  .container .categoryAside .category_ul {
    text-align: left;
  }
  .container .categoryAside .category_ul .category_li {
    margin: 20px;
    font-size: 16px;
    color: #D4D4D4;
    cursor: pointer;
  }
  .container .categoryAside .category_ul .category_li:hover {
    color: #DFC070;
  }
  .container .products_container {
    padding: 20px;
    flex: 1; /* Чтобы занять оставшееся пространство */
  }
  .container .products_container .products_container_h1 {
    margin: 37px 0 20px 0;
    font-size: 24px;
    font-weight: medium;
  }
  .container .products_container .products_block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
    gap: 5px; /* Отступы между товарами */
  }
  .container .products_container .products_control {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
@media (max-width: 1920px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  }
  .container .categoryAside {
    border-right: solid 2px #D4D4D4;
    width: 300px;
    padding: 20px;
  }
  .container .categoryAside .category_h2 {
    font-size: 24px;
    line-height: 18px;
    text-align: center;
    padding-top: 20px;
  }
  .container .categoryAside .category_h2 .category_logo {
    margin-right: 9px;
    height: 24px;
    width: 24px;
  }
  .container .categoryAside .category_ul {
    text-align: left;
  }
  .container .categoryAside .category_ul .category_li {
    margin: 20px;
    font-size: 16px;
    color: #D4D4D4;
    cursor: pointer;
  }
  .container .categoryAside .category_ul .category_li:hover {
    color: #DFC070;
  }
  .container .products_container {
    padding: 20px;
    flex: 1; /* Чтобы занять оставшееся пространство */
  }
  .container .products_container .products_container_h1 {
    margin: 37px 0 20px 0;
    font-size: 24px;
    font-weight: medium;
  }
  .container .products_container .products_block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
    gap: 5px; /* Отступы между товарами */
  }
  .container .products_container .products_control {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
@media (max-width: 1366px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  }
  .container .categoryAside {
    border-right: solid 2px #D4D4D4;
    width: 300px;
    padding: 20px;
  }
  .container .categoryAside .category_h2 {
    font-size: 24px;
    line-height: 18px;
    text-align: center;
    padding-top: 20px;
  }
  .container .categoryAside .category_h2 .category_logo {
    margin-right: 9px;
    height: 24px;
    width: 24px;
  }
  .container .categoryAside .category_ul {
    text-align: left;
  }
  .container .categoryAside .category_ul .category_li {
    margin: 20px;
    font-size: 16px;
    color: #D4D4D4;
    cursor: pointer;
  }
  .container .categoryAside .category_ul .category_li:hover {
    color: #DFC070;
  }
  .container .products_container {
    padding: 20px;
    flex: 1; /* Чтобы занять оставшееся пространство */
  }
  .container .products_container .products_container_h1 {
    margin: 37px 0 20px 0;
    font-size: 24px;
    font-weight: medium;
  }
  .container .products_container .products_block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
    gap: 5px; /* Отступы между товарами */
  }
  .container .products_container .products_control {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
@media (max-width: 1280px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  }
  .container .categoryAside {
    border-right: solid 2px #D4D4D4;
    width: 300px;
    padding: 20px;
  }
  .container .categoryAside .category_h2 {
    font-size: 24px;
    line-height: 18px;
    text-align: center;
    padding-top: 20px;
  }
  .container .categoryAside .category_h2 .category_logo {
    margin-right: 9px;
    height: 24px;
    width: 24px;
  }
  .container .categoryAside .category_ul {
    text-align: left;
  }
  .container .categoryAside .category_ul .category_li {
    margin: 20px;
    font-size: 16px;
    color: #D4D4D4;
    cursor: pointer;
  }
  .container .categoryAside .category_ul .category_li:hover {
    color: #DFC070;
  }
  .container .products_container {
    padding: 20px;
    flex: 1; /* Чтобы занять оставшееся пространство */
  }
  .container .products_container .products_container_h1 {
    margin: 37px 0 20px 0;
    font-size: 24px;
    font-weight: medium;
  }
  .container .products_container .products_block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
    gap: 5px; /* Отступы между товарами */
  }
  .container .products_container .products_control {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
@media (max-width: 1024px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  }
  .container .categoryAside {
    border-right: solid 2px #D4D4D4;
    width: 300px;
    padding: 20px;
  }
  .container .categoryAside .category_h2 {
    font-size: 24px;
    line-height: 18px;
    text-align: center;
    padding-top: 20px;
  }
  .container .categoryAside .category_h2 .category_logo {
    margin-right: 9px;
    height: 24px;
    width: 24px;
  }
  .container .categoryAside .category_ul {
    text-align: left;
  }
  .container .categoryAside .category_ul .category_li {
    margin: 20px;
    font-size: 16px;
    color: #D4D4D4;
    cursor: pointer;
  }
  .container .categoryAside .category_ul .category_li:hover {
    color: #DFC070;
  }
  .container .products_container {
    padding: 20px;
    flex: 1; /* Чтобы занять оставшееся пространство */
  }
  .container .products_container .products_container_h1 {
    margin: 37px 0 20px 0;
    font-size: 24px;
    font-weight: medium;
  }
  .container .products_container .products_block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
    gap: 5px; /* Отступы между товарами */
  }
  .container .products_container .products_control {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
@media (max-width: 720px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  }
  .container .categoryAside {
    border-right: solid 2px #D4D4D4;
    width: 300px;
    padding: 20px;
    display: none;
  }
  .container .categoryAside .category_h2 {
    font-size: 24px;
    line-height: 18px;
    text-align: center;
    padding-top: 20px;
  }
  .container .categoryAside .category_h2 .category_logo {
    margin-right: 9px;
    height: 24px;
    width: 24px;
  }
  .container .categoryAside .category_ul {
    text-align: left;
  }
  .container .categoryAside .category_ul .category_li {
    margin: 20px;
    font-size: 16px;
    color: #D4D4D4;
    cursor: pointer;
  }
  .container .categoryAside .category_ul .category_li:hover {
    color: #DFC070;
  }
  .container .products_container {
    padding: 20px;
    flex: 1; /* Чтобы занять оставшееся пространство */
  }
  .container .products_container .products_container_h1 {
    margin: 37px 0 20px 0;
    font-size: 24px;
    font-weight: medium;
  }
  .container .products_container .products_block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
    gap: 5px; /* Отступы между товарами */
  }
  .container .products_container .products_control {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}/*# sourceMappingURL=products.css.map */