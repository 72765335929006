@import "../../css/colors";
@media (max-width: 4320px) {
    .container3{
        .productWrapper{
            display: flex;
            margin-top: 50px;
            justify-content: space-between;
            .productGeneralInfo{
                border-radius: 4px; 
                box-sizing: border-box;
                width: 30%;
                display: flex;
                .product_image{
                    width: 100%;
                    height: auto;
                }
                // .deactivatedImg{
                //     position: relative;
                //     .product_image{
                //         width: 100%;
                //         height: auto;
                //     }
                //     .deactivated_overlay {
                //         position: absolute; // Позиционирует overlay поверх product_image
                //         top: 0;
                //         left: 0;
                //         width: 100%; // Наложение будет соответствовать размеру изображения продукта
                //         height: 100%;
                //         pointer-events: none; // Чтобы наложение не мешало кликам
                //     }
                // }
                .priceAndName{
                    .productName{
                        margin: 10px;
                        font-size: 25px;
                        width: 1000px;
                    }
                    .availabilityOfProduct{
                        margin: 20px 0 10px 10px;
                        font-size: 15px;
                        color: $secondary_color1;
                    }
                    .productPrice{
                        margin: 20px 0 10px 10px;
                        font-size: 18px;
                        color: $primary_color1;
                    }
                }
            }
            .productOwnerInfo{
                width: 20%;
                .productOwnerImg{
                    width: 80%;
                    height: auto;
                    margin: 10px;
                }
            }
        }
        .hr{
            margin: 50px 0 50px 0;
            border: 1px solid #D4D2E3;
        }
        .productDescription{
            font-size: 18px;
        }
    }
}
@media (max-width: 1920px) {
    .container3{
        .productWrapper{
            display: flex;
            margin-top: 50px;
            justify-content: space-between;
            .productGeneralInfo{
                border-radius: 4px; 
                box-sizing: border-box;
                width: 30%;
                display: flex;
                .product_image{
                    width: 100%;
                    height: auto;
                }
                // .deactivatedImg{
                //     position: relative;
                //     .product_image{
                //         width: 100%;
                //         height: auto;
                //     }
                //     .deactivated_overlay {
                //         position: absolute; // Позиционирует overlay поверх product_image
                //         top: 0;
                //         left: 0;
                //         width: 100%; // Наложение будет соответствовать размеру изображения продукта
                //         height: 100%;
                //         pointer-events: none; // Чтобы наложение не мешало кликам
                //     }
                // }
                .priceAndName{
                    .productName{
                        margin: 10px;
                        font-size: 25px;
                        width: 1000px;
                    }
                    .availabilityOfProduct{
                        margin: 20px 0 10px 10px;
                        font-size: 15px;
                        color: $secondary_color1;
                    }
                    .productPrice{
                        margin: 20px 0 10px 10px;
                        font-size: 18px;
                        color: $primary_color1;
                    }
                }
            }
            .productOwnerInfo{
                width: 20%;
                .productOwnerImg{
                    width: 80%;
                    height: auto;
                    margin: 10px;
                }
            }
        }
        .hr{
            margin: 50px 0 50px 0;
            border: 1px solid #D4D2E3;
        }
        .productDescription{
            font-size: 18px;
        }
    }
}
@media (max-width: 1366px) {
    .container3{
        .productWrapper{
            display: flex;
            margin-top: 50px;
            justify-content: space-between;
            .productGeneralInfo{
                border-radius: 4px; 
                box-sizing: border-box;
                width: 30%;
                display: flex;
                .product_image{
                    width: 100%;
                    height: auto;
                }
                // .deactivatedImg{
                //     position: relative;
                //     .product_image{
                //         width: 100%;
                //         height: auto;
                //     }
                //     .deactivated_overlay {
                //         position: absolute; // Позиционирует overlay поверх product_image
                //         top: 0;
                //         left: 0;
                //         width: 100%; // Наложение будет соответствовать размеру изображения продукта
                //         height: 100%;
                //         pointer-events: none; // Чтобы наложение не мешало кликам
                //     }
                // }
                .priceAndName{
                    .productName{
                        margin: 10px;
                        font-size: 25px;
                        width: 1000px;
                    }
                    .availabilityOfProduct{
                        margin: 20px 0 10px 10px;
                        font-size: 15px;
                        color: $secondary_color1;
                    }
                    .productPrice{
                        margin: 20px 0 10px 10px;
                        font-size: 18px;
                        color: $primary_color1;
                    }
                }
            }
            .productOwnerInfo{
                width: 20%;
                .productOwnerImg{
                    width: 80%;
                    height: auto;
                    margin: 10px;
                }
            }
        }
        .hr{
            margin: 50px 0 50px 0;
            border: 1px solid #D4D2E3;
        }
        .productDescription{
            font-size: 18px;
        }
    }
}
@media (max-width: 1280px) {
    .container3{
        .productWrapper{
            display: flex;
            margin-top: 50px;
            justify-content: space-between;
            .productGeneralInfo{
                border-radius: 4px; 
                box-sizing: border-box;
                width: 30%;
                display: flex;
                .product_image{
                    width: 100%;
                    height: auto;
                }
                // .deactivatedImg{
                //     position: relative;
                //     .product_image{
                //         width: 100%;
                //         height: auto;
                //     }
                //     .deactivated_overlay {
                //         position: absolute; // Позиционирует overlay поверх product_image
                //         top: 0;
                //         left: 0;
                //         width: 100%; // Наложение будет соответствовать размеру изображения продукта
                //         height: 100%;
                //         pointer-events: none; // Чтобы наложение не мешало кликам
                //     }
                // }
                .priceAndName{
                    .productName{
                        margin: 10px;
                        font-size: 25px;
                        width: 1000px;
                    }
                    .availabilityOfProduct{
                        margin: 20px 0 10px 10px;
                        font-size: 15px;
                        color: $secondary_color1;
                    }
                    .productPrice{
                        margin: 20px 0 10px 10px;
                        font-size: 18px;
                        color: $primary_color1;
                    }
                }
            }
            .productOwnerInfo{
                width: 20%;
                .productOwnerImg{
                    width: 80%;
                    height: auto;
                    margin: 10px;
                }
            }
        }
        .hr{
            margin: 50px 0 50px 0;
            border: 1px solid #D4D2E3;
        }
        .productDescription{
            font-size: 18px;
        }
    }
}
@media (max-width: 1024px) {
    .container3{
        .productWrapper{
            display: flex;
            margin-top: 50px;
            justify-content: space-between;
            .productGeneralInfo{
                border-radius: 4px; 
                box-sizing: border-box;
                width: 30%;
                display: flex;
                .product_image{
                    width: 100%;
                    height: auto;
                }
                // .deactivatedImg{
                //     position: relative;
                //     .product_image{
                //         width: 100%;
                //         height: auto;
                //     }
                //     .deactivated_overlay {
                //         position: absolute; // Позиционирует overlay поверх product_image
                //         top: 0;
                //         left: 0;
                //         width: 100%; // Наложение будет соответствовать размеру изображения продукта
                //         height: 100%;
                //         pointer-events: none; // Чтобы наложение не мешало кликам
                //     }
                // }
                .priceAndName{
                    .productName{
                        margin: 10px;
                        font-size: 25px;
                        width: 1000px;
                    }
                    .availabilityOfProduct{
                        margin: 20px 0 10px 10px;
                        font-size: 15px;
                        color: $secondary_color1;
                    }
                    .productPrice{
                        margin: 20px 0 10px 10px;
                        font-size: 18px;
                        color: $primary_color1;
                    }
                }
            }
            .productOwnerInfo{
                width: 20%;
                .productOwnerImg{
                    width: 80%;
                    height: auto;
                    margin: 10px;
                }
            }
        }
        .hr{
            margin: 50px 0 50px 0;
            border: 1px solid #D4D2E3;
        }
        .productDescription{
            font-size: 18px;
        }
    }
}
@media (max-width: 720px) {
    .container3{
        .productWrapper{
            display: block;
            margin: 50px auto;
            .productGeneralInfo{
                border-radius: 4px; 
                box-sizing: border-box;
                width: 30%;
                display: flex;
                .product_image{
                    width: 100%;
                    height: auto;
                }
                // .deactivatedImg{
                //     position: relative;
                //     .product_image{
                //         width: 100%;
                //         height: auto;
                //     }
                //     .deactivated_overlay {
                //         position: absolute; // Позиционирует overlay поверх product_image
                //         top: 0;
                //         left: 0;
                //         width: 100%; // Наложение будет соответствовать размеру изображения продукта
                //         height: 100%;
                //         pointer-events: none; // Чтобы наложение не мешало кликам
                //     }
                // }
                .priceAndName{
                    .productName{
                        margin: 10px;
                        font-size: 25px;
                        width: 200px;
                    }
                    .availabilityOfProduct{
                        margin: 20px 0 10px 10px;
                        font-size: 15px;
                        color: $secondary_color1;
                    }
                    .productPrice{
                        margin: 20px 0 10px 10px;
                        font-size: 18px;
                        color: $primary_color1;
                    }
                }
            }
            .productOwnerInfo{
                margin-top: 40px;
                width: 60%;
                .productOwnerImg{
                    width: 80%;
                    height: auto;
                    margin: 10px;
                }
            }
        }
        .hr{
            margin: 50px 0 50px 0;
            border: 1px solid #D4D2E3;
        }
        .productDescription{
            font-size: 18px;
        }
    }
}