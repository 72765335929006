@media (max-width: 4320px) {
  .main {
    background-color: #F5F5F5;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 60vh;
    align-items: center;
  }
  .main .container3 {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 0 102px 0;
  }
  .main .container3 .popular_category {
    margin: 102px 0 40px 0;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .category {
    display: flex;
    justify-content: center;
  }
  .main .container3 .our_partners {
    margin: 107px 0 0 0;
  }
  .main .container3 .our_partners .our_partners_text {
    margin-bottom: 45px;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .our_partners .partners {
    width: 674px;
    height: 77.42px;
    background-color: #25441d;
    margin: 0 auto;
  }
}
@media (max-width: 1920px) {
  .main {
    background-color: #F5F5F5;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 60vh;
    align-items: center;
  }
  .main .container3 {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 0 102px 0;
  }
  .main .container3 .popular_category {
    margin: 102px 0 40px 0;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .category {
    display: flex;
    justify-content: center;
  }
  .main .container3 .our_partners {
    margin: 107px 0 0 0;
  }
  .main .container3 .our_partners .our_partners_text {
    margin-bottom: 45px;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .our_partners .partners {
    width: 674px;
    height: 77.42px;
    background-color: #25441d;
    margin: 0 auto;
  }
}
@media (max-width: 1366px) {
  .main {
    background-color: #F5F5F5;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 60vh;
    align-items: center;
  }
  .main .container3 {
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 0 102px 0;
  }
  .main .container3 .popular_category {
    margin: 102px 0 40px 0;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .category {
    display: flex;
    justify-content: center;
  }
  .main .container3 .our_partners {
    margin: 107px 0 0 0;
  }
  .main .container3 .our_partners .our_partners_text {
    margin-bottom: 45px;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .our_partners .partners {
    width: 674px;
    height: 77.42px;
    background-color: #25441d;
    margin: 0 auto;
  }
}
@media (max-width: 1280px) {
  .main {
    background-color: #F5F5F5;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 60vh;
    align-items: center;
  }
  .main .container3 {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 0 102px 0;
  }
  .main .container3 .popular_category {
    margin: 102px 0 40px 0;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .category {
    display: flex;
    justify-content: center;
  }
  .main .container3 .our_partners {
    margin: 107px 0 0 0;
  }
  .main .container3 .our_partners .our_partners_text {
    margin-bottom: 45px;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .our_partners .partners {
    width: 674px;
    height: 77.42px;
    background-color: #25441d;
    margin: 0 auto;
  }
}
@media (max-width: 1024px) {
  .main {
    background-color: #F5F5F5;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 60vh;
    align-items: center;
  }
  .main .container3 {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 0 102px 0;
  }
  .main .container3 .popular_category {
    margin: 102px 0 40px 0;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .category {
    display: flex;
    justify-content: center;
  }
  .main .container3 .our_partners {
    margin: 107px 0 0 0;
  }
  .main .container3 .our_partners .our_partners_text {
    margin-bottom: 45px;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .our_partners .partners {
    width: 674px;
    height: 77.42px;
    background-color: #25441d;
    margin: 0 auto;
  }
}
@media (max-width: 720px) {
  .main {
    background-color: #F5F5F5;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 60vh;
    align-items: center;
  }
  .main .container3 {
    max-width: 300px;
    margin: 0 auto;
    padding: 0 0 102px 0;
  }
  .main .container3 .wp_logo_main {
    width: 300px;
    margin: 0 auto;
  }
  .main .container3 .popular_category {
    margin: 102px 0 40px 0;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .category {
    display: flex;
    justify-content: center;
  }
  .main .container3 .our_partners {
    margin: 107px 0 0 0;
  }
  .main .container3 .our_partners .our_partners_text {
    margin-bottom: 45px;
    color: #000;
    font-size: 36px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .main .container3 .our_partners .partners {
    width: 674px;
    height: 77.42px;
    background-color: #25441d;
    margin: 0 auto;
  }
}/*# sourceMappingURL=main.css.map */