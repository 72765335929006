@import "../../css/colors";
.button{
    button{
        background-color: $primary_color1;
        padding: 5px 12px 5px 12px;
        color: $secondary_color2;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        border-radius: 4px;
        cursor: pointer;
        margin: 0 10px 10px 10px;
    }
}