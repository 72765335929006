.button button {
  background-color: #222E56;
  padding: 5px 12px 5px 12px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px 10px 10px;
}/*# sourceMappingURL=button.css.map */