@charset "UTF-8";
/*Обнуление*/
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,700&family=Roboto:wght@400;500&display=swap");
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  font-family: Arial;
  font-weight: 400;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input, button, textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 400;
}

/*Обнуление*/
/*Шрифт*/
/*Шрифт*/
.error {
  font-size: 50px;
}

.category_title {
  display: flex;
  height: auto;
  width: auto;
}

.category_title_img {
  width: 24px;
  height: 24px;
}

.login {
  max-height: 146.8px;
}

.login-checkbox-label {
  display: flex;
}

.remember_forgot {
  display: flex;
}

.error {
  color: #ff0000;
}

.registration {
  max-height: 146.8px;
}

.login input {
  padding-left: 5px;
  color: #737373;
  display: flex;
  margin: 10px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.registration input {
  padding-left: 5px;
  color: #737373;
  display: flex;
  margin: 10px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.login h4 {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.registration h4 {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}/*# sourceMappingURL=style.css.map */