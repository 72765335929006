.admin_panel_window{
    .admin_panel_add_new_product_form{
        input, .category_select, label, textarea{
            border-radius: 5px;
            width: 200px;
            display: flex;
            margin: 10px;
            padding: 3px;
        }
        .price_and_unit{
            display: flex;
            input{
                border-radius: 5px 0 0 5px;
                width: 140px;
                display: flex;
                margin: 10px 0 10px 10px;
                padding: 3px;
            }
            .unit_select{
                border-radius: 0 5px 5px 0;
                margin: 10px 0 10px 0;
                width: 60px;
                display: flex;
                padding: 3px;
            }
        }
        .admin_panel_add_new_product_button{
            padding: 5px;
            margin: 0 10px;
        }
    }
}