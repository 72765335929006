$primary_color1: #222E56;
$primary_color2: #DFC070;
$primary_color3: #A1ABCD;
$primary_color4: #F4EDD9;

$secondary_color1: #000000;
$secondary_color2: #FFFFFF;

$warning_color1: #F59E0B;
$warning_color2: #FBBF24;

$destructive_color1: #EF4444;
$destructive_color2: #DC2626;

$neutral_color1: #FAFAFA;
$neutral_color2: #F5F5F5;
$neutral_color3: #E5E5E5;
$neutral_color4: #D4D4D4;
$neutral_color5: #A3A3A3;
$neutral_color6: #737373;
$neutral_color7: #525252;
$neutral_color8: #404040;
$neutral_color9: #262626;
$neutral_color10: #171717;