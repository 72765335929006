@charset "UTF-8";
@media (max-width: 4320px) {
  .header_wrapper {
    width: 100%;
    height: 80px;
  }
  .header_wrapper .header {
    width: 100%;
    background-color: #DFC070;
    height: 80px;
    position: fixed;
    z-index: 1000;
  }
  .header_wrapper .header .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  }
  .header_wrapper .header .container .wallpaper_logo {
    width: auto;
    height: 40px;
    margin: 20px 40px 0 0;
  }
  .header_wrapper .header .container form.search {
    display: flex; /* Используем flexbox для выравнивания элементов в строку */
  }
  .header_wrapper .header .container .search {
    margin: 20px 0 0 130.5px;
  }
  .header_wrapper .header .container .search .search_tab {
    padding: 11px 0 11px 16px;
    color: #737373;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    width: 258px;
    margin-right: 0;
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .search .category_select {
    width: 139px;
    border-left: solid 1px #A3A3A3;
    padding: 0 0 0 13px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .header_wrapper .header .container .search .search_submit_button {
    width: 47px;
    height: 40px;
    background-color: #222E56;
    border-radius: 0 4px 4px 0;
  }
  .header_wrapper .header .container .nav {
    display: flex;
  }
  .header_wrapper .header .container .nav .navigation {
    display: flex;
    align-items: center;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu {
    display: flex;
    margin: 11px 42px 0 0;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link {
    margin: 0 14px 0 14px;
    color: #222E56;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    padding: 0;
    border-radius: 0;
    margin: -2px 14px 0 14px;
    color: #222E56;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart {
    margin: 6px 42px 0 0;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 4px;
    padding: 2px;
    display: flex;
    height: 26px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart:hover {
    background-color: #737373;
    transition: 0.3s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .add_shopping_cart_img {
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .cart_text {
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin: 3px 0 3px 0;
  }
  .header_wrapper .header .container .nav .navigation .language_bar {
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .nav .navigation .language_bar_flag {
    width: 48px;
    height: 40px;
  }
}
@media (max-width: 1920px) {
  .header_wrapper {
    width: 100%;
    height: 80px;
  }
  .header_wrapper .header {
    width: 100%;
    background-color: #DFC070;
    height: 80px;
    position: fixed;
    z-index: 1000;
  }
  .header_wrapper .header .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  }
  .header_wrapper .header .container .wallpaper_logo {
    width: auto;
    height: 40px;
  }
  .header_wrapper .header .container form.search {
    display: flex; /* Используем flexbox для выравнивания элементов в строку */
  }
  .header_wrapper .header .container .search {
    margin: 20px 0 0 130.5px;
  }
  .header_wrapper .header .container .search .search_tab {
    padding: 11px 0 11px 16px;
    color: #737373;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    width: 258px;
    margin-right: 0;
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .search .category_select {
    width: 139px;
    border-left: solid 1px #A3A3A3;
    padding: 0 0 0 13px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .header_wrapper .header .container .search .search_submit_button {
    width: 47px;
    height: 40px;
    background-color: #222E56;
    border-radius: 0 4px 4px 0;
  }
  .header_wrapper .header .container .nav {
    display: flex;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link {
    color: #222E56;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    padding: 0;
    border-radius: 0;
    margin: -2px 14px 0 14px;
    color: #222E56;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart {
    margin: 6px 42px 0 0;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 4px;
    padding: 2px;
    display: flex;
    height: 26px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart:hover {
    background-color: #737373;
    transition: 0.3s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .add_shopping_cart_img {
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .cart_text {
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin: 3px 0 3px 0;
  }
  .header_wrapper .header .container .nav .navigation .language_bar {
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .nav .navigation .language_bar_flag {
    width: 48px;
    height: 40px;
  }
}
@media (max-width: 1366px) {
  .header_wrapper {
    width: 100%;
    height: 70px;
  }
  .header_wrapper .header {
    width: 100%;
    background-color: #DFC070;
    height: 70px;
    position: fixed;
    z-index: 1000;
  }
  .header_wrapper .header .container {
    max-width: 1366px;
    margin: 0 auto;
    display: flex;
  }
  .header_wrapper .header .container .wallpaper_logo {
    display: flex;
    width: auto;
    height: 30px;
    margin: 20px 40px 0 20px;
  }
  .header_wrapper .header .container form.search {
    display: flex; /* Используем flexbox для выравнивания элементов в строку */
  }
  .header_wrapper .header .container .search {
    margin: 20px 0 0 130.5px;
  }
  .header_wrapper .header .container .search .search_tab {
    padding: 11px 0 11px 16px;
    color: #737373;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    width: 258px;
    margin-right: 0;
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .search .category_select {
    width: 139px;
    border-left: solid 1px #A3A3A3;
    padding: 0 0 0 13px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .header_wrapper .header .container .search .search_submit_button {
    width: 47px;
    height: 40px;
    background-color: #222E56;
    border-radius: 0 4px 4px 0;
  }
  .header_wrapper .header .container .nav {
    display: flex;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu {
    display: flex;
    margin: 18px 42px 0 0;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link {
    margin: 0 14px 0 14px;
    color: #222E56;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    padding: 0;
    border-radius: 0;
    margin: -2px 14px 0 14px;
    color: #222E56;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart {
    margin: 6px 42px 0 0;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 4px;
    padding: 2px;
    display: flex;
    height: 26px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart:hover {
    background-color: #737373;
    transition: 0.3s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .add_shopping_cart_img {
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .cart_text {
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin: 3px 0 3px 0;
  }
  .header_wrapper .header .container .nav .navigation .language_bar {
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .nav .navigation .language_bar_flag {
    width: 48px;
    height: 40px;
  }
}
@media (max-width: 1280px) {
  .header_wrapper {
    width: 100%;
    height: 70px;
  }
  .header_wrapper .header {
    width: 100%;
    background-color: #DFC070;
    height: 70px;
    position: fixed;
    z-index: 1000;
  }
  .header_wrapper .header .container {
    max-width: 1280px;
    margin: 0 20px;
    display: flex;
  }
  .header_wrapper .header .container .wallpaper_logo {
    width: auto;
    height: 30px;
    align-items: center;
    justify-content: center;
  }
  .header_wrapper .header .container form.search {
    display: flex; /* Используем flexbox для выравнивания элементов в строку */
  }
  .header_wrapper .header .container .search {
    margin: 20px 0 0 130.5px;
  }
  .header_wrapper .header .container .search .search_tab {
    padding: 11px 0 11px 16px;
    color: #737373;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    width: 258px;
    margin-right: 0;
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .search .category_select {
    width: 139px;
    border-left: solid 1px #A3A3A3;
    padding: 0 0 0 13px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .header_wrapper .header .container .search .search_submit_button {
    width: 47px;
    height: 40px;
    background-color: #222E56;
    border-radius: 0 4px 4px 0;
  }
  .header_wrapper .header .container .nav {
    display: flex;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu {
    display: flex;
    margin: 18px 42px 0 0;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link {
    margin: 0 14px 0 14px;
    color: #222E56;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    padding: 0;
    border-radius: 0;
    margin: -2px 14px 0 14px;
    color: #222E56;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart {
    margin: 6px 42px 0 0;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 4px;
    padding: 2px;
    display: flex;
    height: 26px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart:hover {
    background-color: #737373;
    transition: 0.3s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .add_shopping_cart_img {
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .cart_text {
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin: 3px 0 3px 0;
  }
  .header_wrapper .header .container .nav .navigation .language_bar {
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .nav .navigation .language_bar_flag {
    width: 48px;
    height: 40px;
  }
}
@media (max-width: 1024px) {
  .header_wrapper {
    width: 100%;
    height: 80px;
  }
  .header_wrapper .header {
    width: 100%;
    background-color: #DFC070;
    height: 80px;
    position: fixed;
    z-index: 1000;
  }
  .header_wrapper .header .container {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
  }
  .header_wrapper .header .container .wallpaper_logo {
    width: auto;
    height: 35px;
    margin: 25px 0 0 25px;
  }
  .header_wrapper .header .container form.search {
    display: flex; /* Используем flexbox для выравнивания элементов в строку */
  }
  .header_wrapper .header .container .search {
    margin: 20px 0 0 130.5px;
  }
  .header_wrapper .header .container .search .search_tab {
    padding: 11px 0 11px 16px;
    color: #737373;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    width: 258px;
    margin-right: 0;
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .search .category_select {
    width: 139px;
    border-left: solid 1px #A3A3A3;
    padding: 0 0 0 13px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .header_wrapper .header .container .search .search_submit_button {
    width: 47px;
    height: 40px;
    background-color: #222E56;
    border-radius: 0 4px 4px 0;
  }
  .header_wrapper .header .container .nav .navigation {
    display: flex;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu {
    display: flex;
    margin: 19px 42px 0 20px;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link {
    margin: 0 14px 0 14px;
    color: #222E56;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    padding: 0;
    border-radius: 0;
    margin: -2px 14px 0 14px;
    color: #222E56;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart {
    margin: 6px 42px 0 0;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 4px;
    padding: 2px;
    display: flex;
    height: 26px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart:hover {
    background-color: #737373;
    transition: 0.3s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .add_shopping_cart_img {
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .cart_text {
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin: 3px 0 3px 0;
  }
  .header_wrapper .header .container .nav .navigation .language_bar {
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .nav .navigation .language_bar_flag {
    width: 48px;
    height: 40px;
  }
}
@media (max-width: 720px) {
  .header_wrapper {
    width: 100%;
    height: 80px;
  }
  .header_wrapper .header {
    width: 100%;
    background-color: #DFC070;
    height: 80px;
    position: fixed;
    z-index: 1000;
  }
  .header_wrapper .header .container {
    max-width: 720px;
    margin: 0 auto;
    display: flex;
  }
  .header_wrapper .header .container .wallpaper_logo {
    width: auto;
    height: 35px;
    margin: 25px 0 0 25px;
  }
  .header_wrapper .header .container form.search {
    display: flex; /* Используем flexbox для выравнивания элементов в строку */
  }
  .header_wrapper .header .container .search {
    margin: 20px 0 0 130.5px;
  }
  .header_wrapper .header .container .search .search_tab {
    padding: 11px 0 11px 16px;
    color: #737373;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    width: 258px;
    margin-right: 0;
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .search .category_select {
    width: 139px;
    border-left: solid 1px #A3A3A3;
    padding: 0 0 0 13px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
  .header_wrapper .header .container .search .search_submit_button {
    width: 47px;
    height: 40px;
    background-color: #222E56;
    border-radius: 0 4px 4px 0;
  }
  .header_wrapper .header .container .nav .navigation {
    display: flex;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu {
    display: flex;
    align-items: center;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link {
    margin: 0 3px 0 3px;
    color: #222E56;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu .nav_link:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    padding: 0;
    border-radius: 0;
    margin: -2px 14px 0 14px;
    color: #222E56;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  .header_wrapper .header .container .nav .navigation .navigation_menu button:hover {
    cursor: pointer;
    color: #FFFFFF;
    transition: 0.2s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart {
    margin: 6px 42px 0 0;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 4px;
    padding: 2px;
    display: flex;
    height: 26px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart:hover {
    background-color: #737373;
    transition: 0.3s;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .add_shopping_cart_img {
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
  }
  .header_wrapper .header .container .nav .navigation .shoping_cart .cart_text {
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin: 3px 0 3px 0;
  }
  .header_wrapper .header .container .nav .navigation .language_bar {
    border-radius: 4px 0 0 4px;
  }
  .header_wrapper .header .container .nav .navigation .language_bar_flag {
    width: 48px;
    height: 40px;
  }
}/*# sourceMappingURL=header.css.map */