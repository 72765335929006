@import "../../css/colors";

@media (max-width: 4096px) {
    .footer{
        width: 100%;
        background-color: $primary_color1;
        height: 446px;
        .container2{
            max-width: 1440px;
            margin: 0 auto;
            padding: 74px 110px 0 110px;
            .footer_container{
                display: flex;
                justify-content: space-between;
                .footer_container_1{
                    .footer_container_1_img{
                        width: 244px;
                        height: 53px;
                        margin: 0 0 43px 0;
                    }
                    .footer_container_1_2{
                        display: flex;
                        justify-content: space-between;
                    }
                    .footer_container_1_3{
                        margin: 20px 0 0 0;
                        a{
                            color: $secondary_color2;
                        }
                    }
                }
                .footer_container_2{
                    display: flex;
                    .products{
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                    .contact{
                        margin: 0 0 0 93.47px;
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                }
                .copyright{
                    text-align: center;
                    margin: 32px 0 0 0;
                    .colored_copyright{
                        color: $primary_color2;
                    }
                    .colored_copyright2{
                        color: #E3E3E3;
                    }
                    .colored_copyright3{
                        color: #8B8B8B;
                    }
                }
            }
            .hr{
                margin: 110px 0 0 0;
                border: 1px solid #D4D2E3;
            }
            .copyright {
                text-align: center;
                margin: 32px 0 0 0;
                .colored_copyright {
                    color: #DFC070;
                  }
                  
                  .colored_copyright2 {
                    color: #E3E3E3;
                  }
                  
                  .colored_copyright3 {
                    color: #8B8B8B;
                  }
            }
            .createdBy{
                text-align: center;
                margin: 32px 0 0 0;
                color: $neutral_color4;
                a{
                    color: $secondary_color2;
                }
            }
        }
    }
}
@media (max-width: 2560px) {
    .footer{
        width: 100%;
        background-color: $primary_color1;
        height: 446px;
        .container2{
            max-width: 1440px;
            margin: 0 auto;
            padding: 74px 110px 0 110px;
            .footer_container{
                display: flex;
                justify-content: space-between;
                .footer_container_1{
                    .footer_container_1_img{
                        width: 244px;
                        height: 53px;
                        margin: 0 0 43px 0;
                    }
                    .footer_container_1_2{
                        display: flex;
                        justify-content: space-between;
                    }
                    .footer_container_1_3{
                        margin: 20px 0 0 0;
                        a{
                            color: $secondary_color2;
                        }
                    }
                }
                .footer_container_2{
                    display: flex;
                    .products{
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                    .contact{
                        margin: 0 0 0 93.47px;
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                }
                .copyright{
                    text-align: center;
                    margin: 32px 0 0 0;
                    .colored_copyright{
                        color: $primary_color2;
                    }
                    .colored_copyright2{
                        color: #E3E3E3;
                    }
                    .colored_copyright3{
                        color: #8B8B8B;
                    }
                }
            }
            .hr{
                margin: 110px 0 0 0;
                border: 1px solid #D4D2E3;
            }
            .copyright {
                text-align: center;
                margin: 32px 0 0 0;
                .colored_copyright {
                    color: #DFC070;
                  }
                  
                  .colored_copyright2 {
                    color: #E3E3E3;
                  }
                  
                  .colored_copyright3 {
                    color: #8B8B8B;
                  }
            }
            .createdBy{
                text-align: center;
                margin: 32px 0 0 0;
                color: $neutral_color4;
                a{
                    color: $secondary_color2;
                }
            }
        }
    }
}
@media (max-width: 1920px) {
    .footer{
        width: 100%;
        background-color: $primary_color1;
        height: 446px;
        .container2{
            max-width: 1440px;
            margin: 0 auto;
            padding: 74px 110px 0 110px;
            .footer_container{
                display: flex;
                justify-content: space-between;
                .footer_container_1{
                    .footer_container_1_img{
                        width: 244px;
                        height: 53px;
                        margin: 0 0 43px 0;
                    }
                    .footer_container_1_2{
                        display: flex;
                        justify-content: space-between;
                    }
                    .footer_container_1_3{
                        margin: 20px 0 0 0;
                        a{
                            color: $secondary_color2;
                        }
                    }
                }
                .footer_container_2{
                    display: flex;
                    .products{
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                    .contact{
                        margin: 0 0 0 93.47px;
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                }
                .copyright{
                    text-align: center;
                    margin: 32px 0 0 0;
                    .colored_copyright{
                        color: $primary_color2;
                    }
                    .colored_copyright2{
                        color: #E3E3E3;
                    }
                    .colored_copyright3{
                        color: #8B8B8B;
                    }
                }
            }
            .hr{
                margin: 110px 0 0 0;
                border: 1px solid #D4D2E3;
            }
            .copyright {
                text-align: center;
                margin: 32px 0 0 0;
                .colored_copyright {
                    color: #DFC070;
                  }
                  
                  .colored_copyright2 {
                    color: #E3E3E3;
                  }
                  
                  .colored_copyright3 {
                    color: #8B8B8B;
                  }
            }
            .createdBy{
                text-align: center;
                margin: 32px 0 0 0;
                color: $neutral_color4;
                a{
                    color: $secondary_color2;
                }
            }
        }
    }
}
@media (max-width: 1366px) {
    .footer{
        width: 100%;
        background-color: $primary_color1;
        height: 446px;
        .container2{
            max-width: 1440px;
            margin: 0 auto;
            padding: 74px 110px 0 110px;
            .footer_container{
                display: flex;
                justify-content: space-between;
                .footer_container_1{
                    .footer_container_1_img{
                        width: 244px;
                        height: 53px;
                        margin: 0 0 43px 0;
                    }
                    .footer_container_1_2{
                        display: flex;
                        justify-content: space-between;
                    }
                    .footer_container_1_3{
                        margin: 20px 0 0 0;
                        a{
                            color: $secondary_color2;
                        }
                    }
                }
                .footer_container_2{
                    display: flex;
                    .products{
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                    .contact{
                        margin: 0 0 0 93.47px;
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                }
                .copyright{
                    text-align: center;
                    margin: 32px 0 0 0;
                    .colored_copyright{
                        color: $primary_color2;
                    }
                    .colored_copyright2{
                        color: #E3E3E3;
                    }
                    .colored_copyright3{
                        color: #8B8B8B;
                    }
                }
            }
            .hr{
                margin: 110px 0 0 0;
                border: 1px solid #D4D2E3;
            }
            .copyright {
                text-align: center;
                margin: 32px 0 0 0;
                .colored_copyright {
                    color: #DFC070;
                  }
                  
                  .colored_copyright2 {
                    color: #E3E3E3;
                  }
                  
                  .colored_copyright3 {
                    color: #8B8B8B;
                  }
            }
            .createdBy{
                text-align: center;
                margin: 32px 0 0 0;
                color: $neutral_color4;
                a{
                    color: $secondary_color2;
                }
            }
        }
    }
}
@media (max-width: 1280px) {
    .footer{
        width: 100%;
        background-color: $primary_color1;
        height: 446px;
        .container2{
            max-width: 1440px;
            margin: 0 auto;
            padding: 74px 110px 0 110px;
            .footer_container{
                display: flex;
                justify-content: space-between;
                .footer_container_1{
                    .footer_container_1_img{
                        width: 244px;
                        height: 53px;
                        margin: 0 0 43px 0;
                    }
                    .footer_container_1_2{
                        display: flex;
                        justify-content: space-between;
                    }
                    .footer_container_1_3{
                        margin: 20px 0 0 0;
                        a{
                            color: $secondary_color2;
                        }
                    }
                }
                .footer_container_2{
                    display: flex;
                    .products{
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                    .contact{
                        margin: 0 0 0 93.47px;
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                }
                .copyright{
                    text-align: center;
                    margin: 32px 0 0 0;
                    .colored_copyright{
                        color: $primary_color2;
                    }
                    .colored_copyright2{
                        color: #E3E3E3;
                    }
                    .colored_copyright3{
                        color: #8B8B8B;
                    }
                }
            }
            .hr{
                margin: 110px 0 0 0;
                border: 1px solid #D4D2E3;
            }
            .copyright {
                text-align: center;
                margin: 32px 0 0 0;
                .colored_copyright {
                    color: #DFC070;
                  }
                  
                  .colored_copyright2 {
                    color: #E3E3E3;
                  }
                  
                  .colored_copyright3 {
                    color: #8B8B8B;
                  }
            }
            .createdBy{
                text-align: center;
                margin: 32px 0 0 0;
                color: $neutral_color4;
                a{
                    color: $secondary_color2;
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .footer{
        width: 100%;
        background-color: $primary_color1;
        height: 446px;
        .container2{
            max-width: 1440px;
            margin: 0 auto;
            padding: 74px 110px 0 110px;
            .footer_container{
                display: flex;
                justify-content: space-between;
                .footer_container_1{
                    .footer_container_1_img{
                        width: 244px;
                        height: 53px;
                        margin: 0 0 43px 0;
                    }
                    .footer_container_1_2{
                        display: flex;
                        justify-content: space-between;
                    }
                    .footer_container_1_3{
                        margin: 20px 0 0 0;
                        a{
                            color: $secondary_color2;
                        }
                    }
                }
                .footer_container_2{
                    display: flex;
                    .products{
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                    .contact{
                        margin: 0 0 0 93.47px;
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                }
                .copyright{
                    text-align: center;
                    margin: 32px 0 0 0;
                    .colored_copyright{
                        color: $primary_color2;
                    }
                    .colored_copyright2{
                        color: #E3E3E3;
                    }
                    .colored_copyright3{
                        color: #8B8B8B;
                    }
                }
            }
            .hr{
                margin: 110px 0 0 0;
                border: 1px solid #D4D2E3;
            }
            .copyright {
                text-align: center;
                margin: 32px 0 0 0;
                .colored_copyright {
                    color: #DFC070;
                  }
                  
                  .colored_copyright2 {
                    color: #E3E3E3;
                  }
                  
                  .colored_copyright3 {
                    color: #8B8B8B;
                  }
            }
            .createdBy{
                text-align: center;
                margin: 32px 0 0 0;
                color: $neutral_color4;
                a{
                    color: $secondary_color2;
                }
            }
        }
    }
}
@media (max-width: 720px) {
    .footer{
        width: 100%;
        background-color: $primary_color1;
        height: auto;
        padding-bottom: 32px;
        .container2{
            max-width: 720px;
            margin: 0 auto;
            padding: 0 auto;
            .footer_container{
                display: block;
                .footer_container_1{
                    .footer_container_1_img{
                        width: 244px;
                        height: 53px;
                        margin: 0 0 43px 0;
                    }
                    .footer_container_1_2{
                        display: flex;
                        justify-content: space-between;
                    }
                    .footer_container_1_3{
                        margin: 20px auto;
                        a{
                            color: $secondary_color2;
                        }
                    }
                }
                .footer_container_2{
                    display: flex;
                    .products{
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            margin: 0 0 22px 0;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                            margin: 0 0 18px 0;
                        }
                    }
                    .contact{
                        margin: 0 auto;
                        .footer_container_2_text{
                            color: $primary_color2;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                        }
                        .footer_container_2_links{
                            color: $secondary_color2;
                            font-size: 18px;
                            font-family: 'DM Sans', sans-serif;
                            display: block;
                        }
                    }
                }
                .copyright{
                    text-align: center;
                    margin: 32px 0 0 0;
                    .colored_copyright{
                        color: $primary_color2;
                    }
                    .colored_copyright2{
                        color: #E3E3E3;
                    }
                    .colored_copyright3{
                        color: #8B8B8B;
                    }
                }
            }
            .hr{
                margin: 32px 0 0 0;
                border: 1px solid #D4D2E3;
            }
            .copyright {
                text-align: center;
                margin: 32px 0 0 0;
                .colored_copyright {
                    color: #DFC070;
                  }
                  
                  .colored_copyright2 {
                    color: #E3E3E3;
                  }
                  
                  .colored_copyright3 {
                    color: #8B8B8B;
                  }
            }
            .createdBy{
                text-align: center;
                margin: 32px auto;
                color: $neutral_color4;
                a{
                    color: $secondary_color2;
                }
            }
        }
    }
}