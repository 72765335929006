@media (max-width: 4320px) {
  .adminCompWrapper {
    display: flex;
    width: 400px;
    background-color: #DFC070;
    padding: 5px;
    height: 60px;
    z-index: 1000;
    border-radius: 3px;
    margin: 0 0 15px 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .adminCompWrapper button {
    margin: 10px auto;
    padding: 5px;
  }
}
@media (max-width: 1920px) {
  .adminCompWrapper {
    display: flex;
    width: 400px;
    background-color: #DFC070;
    padding: 5px;
    height: 60px;
    z-index: 1000;
    border-radius: 3px;
    margin: 0 0 15px 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .adminCompWrapper button {
    margin: 10px auto;
    padding: 5px;
  }
}
@media (max-width: 1366px) {
  .adminCompWrapper {
    display: flex;
    width: 400px;
    background-color: #DFC070;
    padding: 5px;
    height: 60px;
    z-index: 1000;
    border-radius: 3px;
    margin: 0 0 15px 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .adminCompWrapper button {
    margin: 10px auto;
    padding: 5px;
  }
}
@media (max-width: 1280px) {
  .adminCompWrapper {
    display: flex;
    width: 400px;
    background-color: #DFC070;
    padding: 5px;
    height: 60px;
    z-index: 1000;
    border-radius: 3px;
    margin: 0 0 15px 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .adminCompWrapper button {
    margin: 10px auto;
    padding: 5px;
  }
}
@media (max-width: 1024px) {
  .adminCompWrapper {
    display: flex;
    width: 400px;
    background-color: #DFC070;
    padding: 5px;
    height: 60px;
    z-index: 1000;
    border-radius: 3px;
    margin: 0 0 15px 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .adminCompWrapper button {
    margin: 10px auto;
    padding: 5px;
  }
}
@media (max-width: 720px) {
  .adminCompWrapper {
    display: flex;
    width: 300px;
    background-color: #DFC070;
    padding: 5px;
    height: 60px;
    z-index: 1000;
    border-radius: 3px;
    margin: 0 0 15px 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .adminCompWrapper button {
    margin: 5px 2px;
    padding: 5px;
  }
}/*# sourceMappingURL=adminProductEdit.css.map */