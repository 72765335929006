.admin_panel_window{
    .admin_panel_edit_cat_form{
        input, .category_select, label{
            border-radius: 5px;
            width: 200px;
            display: flex;
            margin: 10px;
            padding: 3px;
        }
        .admin_panel_edit_cat_button{
            padding: 5px;
            margin: 0 10px;
        }
    }
}