.admin_panel_window .admin_panel_edit_cat_form input, .admin_panel_window .admin_panel_edit_cat_form .category_select, .admin_panel_window .admin_panel_edit_cat_form label {
  border-radius: 5px;
  width: 200px;
  display: flex;
  margin: 10px;
  padding: 3px;
}
.admin_panel_window .admin_panel_edit_cat_form .admin_panel_edit_cat_button {
  padding: 5px;
  margin: 0 10px;
}/*# sourceMappingURL=editCat.css.map */