@import "../../css/colors";
.sidebar {
    width: 250px;
    background-color: $primary_color3;
    color: #ecf0f1;
    padding: 20px;
    box-sizing: border-box;
    height: 504px;
    .profile {
        text-align: center;
        margin-bottom: 20px;
        .profile-picture {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
            margin-bottom: 10px;
            &:hover{
                border: solid 1px $primary_color1;
                cursor: pointer;
            }
        }
        h2 {
            margin: 10px 0;
        }
    }
    .menu{
        ul {
            list-style: none;
            padding: 0;
        }
        li {
            margin: 10px 0;
        }
        a {
            color: #ecf0f1;
            text-decoration: none;
            display: block;
            padding: 10px 15px;
            border-radius: 4px;
        }
    }
}
.main-content {
    flex-grow: 1;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    h1 {
        font-size: 24px;
        margin-bottom: 20px;
        color: $primary_color1;
    }
    form {
        max-width: 500px;
        .form-group {
            margin-bottom: 15px;
            label {
                display: block;
                margin-bottom: 5px;
                font-weight: bold;
            }
        }
    }
}

.menu a:hover,
.menu .active a {
    background-color: $primary_color1;
}

input[type="text"],
input[type="tel"],
input[type="date"],
select {
    width: calc(100% - 20px);
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
}

.phone-input {
    display: flex;
}

.phone-input select {
    width: 60px;
    margin-right: 10px;
}

.gender-options {
    display: flex;
    align-items: center;
}

.gender-options input {
    margin-right: 5px;
}

.gender-options label {
    margin-right: 20px;
}

.form-buttons {
    display: flex;
    justify-content: space-between;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    border: solid 1px $primary_color1;
    background-color: #fff;
    color: $primary_color1;
}

.save-button {
    background-color: $primary_color1;
    color: #ffffff;
}
