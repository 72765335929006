@import "../../css/colors";
@media (max-width: 4320px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  
    .categoryAside {
      border-right: solid 2px $neutral_color4;
      width: 300px;
      padding: 20px;
      .category_h2 {
        font-size: 24px;
        line-height: 18px;
        text-align: center;
        padding-top: 20px;
        .category_logo {
          margin-right: 9px;
          height: 24px;
          width: 24px;
        }
      }
      .category_ul {
        text-align: left;
        .category_li {
          margin: 20px;
          font-size: 16px;
          color: $neutral_color4;
          cursor: pointer;
          &:hover {
            color: $primary_color2;
          }
        }
      }
    }
  
    .products_container {
      padding: 20px;
      flex: 1; /* Чтобы занять оставшееся пространство */
      .products_container_h1 {
        margin: 37px 0 20px 0;
        font-size: 24px;
        font-weight: medium;
      }
      .products_block {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
          gap: 5px; /* Отступы между товарами */
      }
      .products_control {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 1920px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  
    .categoryAside {
      border-right: solid 2px $neutral_color4;
      width: 300px;
      padding: 20px;
      .category_h2 {
        font-size: 24px;
        line-height: 18px;
        text-align: center;
        padding-top: 20px;
        .category_logo {
          margin-right: 9px;
          height: 24px;
          width: 24px;
        }
      }
      .category_ul {
        text-align: left;
        .category_li {
          margin: 20px;
          font-size: 16px;
          color: $neutral_color4;
          cursor: pointer;
          &:hover {
            color: $primary_color2;
          }
        }
      }
    }
  
    .products_container {
      padding: 20px;
      flex: 1; /* Чтобы занять оставшееся пространство */
      .products_container_h1 {
        margin: 37px 0 20px 0;
        font-size: 24px;
        font-weight: medium;
      }
      .products_block {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
          gap: 5px; /* Отступы между товарами */
      }
      .products_control {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 1366px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  
    .categoryAside {
      border-right: solid 2px $neutral_color4;
      width: 300px;
      padding: 20px;
      .category_h2 {
        font-size: 24px;
        line-height: 18px;
        text-align: center;
        padding-top: 20px;
        .category_logo {
          margin-right: 9px;
          height: 24px;
          width: 24px;
        }
      }
      .category_ul {
        text-align: left;
        .category_li {
          margin: 20px;
          font-size: 16px;
          color: $neutral_color4;
          cursor: pointer;
          &:hover {
            color: $primary_color2;
          }
        }
      }
    }
  
    .products_container {
      padding: 20px;
      flex: 1; /* Чтобы занять оставшееся пространство */
      .products_container_h1 {
        margin: 37px 0 20px 0;
        font-size: 24px;
        font-weight: medium;
      }
      .products_block {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
          gap: 5px; /* Отступы между товарами */
      }
      .products_control {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 1280px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  
    .categoryAside {
      border-right: solid 2px $neutral_color4;
      width: 300px;
      padding: 20px;
      .category_h2 {
        font-size: 24px;
        line-height: 18px;
        text-align: center;
        padding-top: 20px;
        .category_logo {
          margin-right: 9px;
          height: 24px;
          width: 24px;
        }
      }
      .category_ul {
        text-align: left;
        .category_li {
          margin: 20px;
          font-size: 16px;
          color: $neutral_color4;
          cursor: pointer;
          &:hover {
            color: $primary_color2;
          }
        }
      }
    }
  
    .products_container {
      padding: 20px;
      flex: 1; /* Чтобы занять оставшееся пространство */
      .products_container_h1 {
        margin: 37px 0 20px 0;
        font-size: 24px;
        font-weight: medium;
      }
      .products_block {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
          gap: 5px; /* Отступы между товарами */
      }
      .products_control {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  
    .categoryAside {
      border-right: solid 2px $neutral_color4;
      width: 300px;
      padding: 20px;
      .category_h2 {
        font-size: 24px;
        line-height: 18px;
        text-align: center;
        padding-top: 20px;
        .category_logo {
          margin-right: 9px;
          height: 24px;
          width: 24px;
        }
      }
      .category_ul {
        text-align: left;
        .category_li {
          margin: 20px;
          font-size: 16px;
          color: $neutral_color4;
          cursor: pointer;
          &:hover {
            color: $primary_color2;
          }
        }
      }
    }
  
    .products_container {
      padding: 20px;
      flex: 1; /* Чтобы занять оставшееся пространство */
      .products_container_h1 {
        margin: 37px 0 20px 0;
        font-size: 24px;
        font-weight: medium;
      }
      .products_block {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
          gap: 5px; /* Отступы между товарами */
      }
      .products_control {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 720px) {
  .container {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  
    .categoryAside {
      border-right: solid 2px $neutral_color4;
      width: 300px;
      padding: 20px;
      display: none;
      .category_h2 {
        font-size: 24px;
        line-height: 18px;
        text-align: center;
        padding-top: 20px;
        .category_logo {
          margin-right: 9px;
          height: 24px;
          width: 24px;
        }
      }
      .category_ul {
        text-align: left;
        .category_li {
          margin: 20px;
          font-size: 16px;
          color: $neutral_color4;
          cursor: pointer;
          &:hover {
            color: $primary_color2;
          }
        }
      }
    }
  
    .products_container {
      padding: 20px;
      flex: 1; /* Чтобы занять оставшееся пространство */
      .products_container_h1 {
        margin: 37px 0 20px 0;
        font-size: 24px;
        font-weight: medium;
      }
      .products_block {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* автоматическое размещение в зависимости от ширины контейнера */
          gap: 5px; /* Отступы между товарами */
      }
      .products_control {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}