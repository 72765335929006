.reg_success_block {
  display: flex;
  justify-content: center;
  min-height: 60vh;
  align-items: center;
}
.reg_success_block .h1_reg_success {
  font-size: 28px;
  font-weight: 600;
}

.reg_success_block_link {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin: 25px;
  padding: 10px;
}/*# sourceMappingURL=regs.css.map */