@media (max-width: 4320px) {
  .container .admin_panel {
    display: flex;
    max-height: 90vh;
  }
  .container .admin_panel .admin_panel_aside {
    border: solid 1px #000000;
    display: flex;
    flex-direction: column;
    background-color: #777;
  }
  .container .admin_panel .admin_panel_aside h1 {
    padding: 30px;
    font-size: 20px;
    border-bottom: solid 1px #000000;
    color: #000000;
  }
  .container .admin_panel .admin_panel_aside h1:hover {
    background-color: #5e5e5e;
  }
  .container .admin_panel .admin_panel_aside h2 {
    font-size: 22px;
    margin-top: 240px;
    text-align: center;
    position: relative;
  }
  .container .admin_panel .admin_panel_aside ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .container .admin_panel .admin_panel_aside ul li {
    color: #FFFFFF;
    padding: 20px;
    height: auto;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .container .admin_panel .admin_panel_aside ul li:hover {
    background-color: #383838;
  }
  .container .admin_panel .admin_panel_window {
    background-color: #9e9e9e;
    width: 1200px;
  }
  .container .admin_panel .admin_panel_window .wallpaper_market_logo {
    margin: 322.5px auto;
    width: 250px;
    display: block;
    opacity: 70%;
  }
}
@media (max-width: 1920px) {
  .container .admin_panel {
    display: flex;
    max-height: 90vh;
  }
  .container .admin_panel .admin_panel_aside {
    border: solid 1px #000000;
    display: flex;
    flex-direction: column;
    background-color: #777;
  }
  .container .admin_panel .admin_panel_aside h1 {
    padding: 30px;
    font-size: 20px;
    border-bottom: solid 1px #000000;
    color: #000000;
  }
  .container .admin_panel .admin_panel_aside h1:hover {
    background-color: #5e5e5e;
  }
  .container .admin_panel .admin_panel_aside h2 {
    font-size: 22px;
    margin-top: 240px;
    text-align: center;
    position: relative;
  }
  .container .admin_panel .admin_panel_aside ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .container .admin_panel .admin_panel_aside ul li {
    color: #FFFFFF;
    padding: 20px;
    height: auto;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .container .admin_panel .admin_panel_aside ul li:hover {
    background-color: #383838;
  }
  .container .admin_panel .admin_panel_window {
    background-color: #9e9e9e;
    width: 1200px;
  }
  .container .admin_panel .admin_panel_window .wallpaper_market_logo {
    margin: 322.5px auto;
    width: 250px;
    display: block;
    opacity: 70%;
  }
}
@media (max-width: 1366px) {
  .container .admin_panel {
    display: flex;
    max-height: 90vh;
  }
  .container .admin_panel .admin_panel_aside {
    border: solid 1px #000000;
    display: flex;
    flex-direction: column;
    background-color: #777;
  }
  .container .admin_panel .admin_panel_aside h1 {
    padding: 30px;
    font-size: 20px;
    border-bottom: solid 1px #000000;
    color: #000000;
  }
  .container .admin_panel .admin_panel_aside h1:hover {
    background-color: #5e5e5e;
  }
  .container .admin_panel .admin_panel_aside h2 {
    font-size: 22px;
    margin-top: 240px;
    text-align: center;
    position: relative;
  }
  .container .admin_panel .admin_panel_aside ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .container .admin_panel .admin_panel_aside ul li {
    color: #FFFFFF;
    padding: 20px;
    height: auto;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .container .admin_panel .admin_panel_aside ul li:hover {
    background-color: #383838;
  }
  .container .admin_panel .admin_panel_window {
    background-color: #9e9e9e;
    width: 1100px;
  }
  .container .admin_panel .admin_panel_window .wallpaper_market_logo {
    margin: 322.5px auto;
    width: 250px;
    display: block;
    opacity: 70%;
  }
}
@media (max-width: 1280px) {
  .container .admin_panel {
    display: flex;
    max-height: 90vh;
  }
  .container .admin_panel .admin_panel_aside {
    border: solid 1px #000000;
    display: flex;
    flex-direction: column;
    background-color: #777;
  }
  .container .admin_panel .admin_panel_aside h1 {
    padding: 30px;
    font-size: 20px;
    border-bottom: solid 1px #000000;
    color: #000000;
  }
  .container .admin_panel .admin_panel_aside h1:hover {
    background-color: #5e5e5e;
  }
  .container .admin_panel .admin_panel_aside h2 {
    font-size: 22px;
    margin-top: 240px;
    text-align: center;
    position: relative;
  }
  .container .admin_panel .admin_panel_aside ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .container .admin_panel .admin_panel_aside ul li {
    color: #FFFFFF;
    padding: 20px;
    height: auto;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .container .admin_panel .admin_panel_aside ul li:hover {
    background-color: #383838;
  }
  .container .admin_panel .admin_panel_window {
    background-color: #9e9e9e;
    width: 1000px;
  }
  .container .admin_panel .admin_panel_window .wallpaper_market_logo {
    margin: 322.5px auto;
    width: 250px;
    display: block;
    opacity: 70%;
  }
}
@media (max-width: 1024px) {
  .container .admin_panel {
    display: flex;
    max-height: 90vh;
  }
  .container .admin_panel .admin_panel_aside {
    border: solid 1px #000000;
    display: flex;
    flex-direction: column;
    background-color: #777;
  }
  .container .admin_panel .admin_panel_aside h1 {
    padding: 30px;
    font-size: 20px;
    border-bottom: solid 1px #000000;
    color: #000000;
  }
  .container .admin_panel .admin_panel_aside h1:hover {
    background-color: #5e5e5e;
  }
  .container .admin_panel .admin_panel_aside h2 {
    font-size: 22px;
    margin-top: 240px;
    text-align: center;
    position: relative;
  }
  .container .admin_panel .admin_panel_aside ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .container .admin_panel .admin_panel_aside ul li {
    color: #FFFFFF;
    padding: 20px;
    height: auto;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .container .admin_panel .admin_panel_aside ul li:hover {
    background-color: #383838;
  }
  .container .admin_panel .admin_panel_window {
    background-color: #9e9e9e;
    width: 800px;
  }
  .container .admin_panel .admin_panel_window .wallpaper_market_logo {
    margin: 322.5px auto;
    width: 250px;
    display: block;
    opacity: 70%;
  }
}
@media (max-width: 720px) {
  .container .admin_panel {
    display: flex;
    max-height: 90vh;
  }
  .container .admin_panel .admin_panel_aside {
    border: solid 1px #000000;
    display: none;
    flex-direction: column;
    background-color: #777;
  }
  .container .admin_panel .admin_panel_aside h1 {
    padding: 30px;
    font-size: 20px;
    border-bottom: solid 1px #000000;
    color: #000000;
  }
  .container .admin_panel .admin_panel_aside h1:hover {
    background-color: #5e5e5e;
  }
  .container .admin_panel .admin_panel_aside h2 {
    font-size: 22px;
    margin-top: 240px;
    text-align: center;
    position: relative;
  }
  .container .admin_panel .admin_panel_aside ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .container .admin_panel .admin_panel_aside ul li {
    color: #FFFFFF;
    padding: 20px;
    height: auto;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
  .container .admin_panel .admin_panel_aside ul li:hover {
    background-color: #383838;
  }
  .container .admin_panel .admin_panel_window {
    background-color: #9e9e9e;
    width: 414px;
  }
  .container .admin_panel .admin_panel_window .wallpaper_market_logo {
    margin: 322.5px auto;
    width: 250px;
    display: block;
    opacity: 70%;
  }
}/*# sourceMappingURL=admin_panel.css.map */