@import "./reseting";
@import "./colors";

/*Шрифт*/
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,700&family=Roboto:wght@400;500&display=swap');
/*Шрифт*/
.error{
	font-size: 50px;
}
.category_title{
	display: flex;
	height: auto;
	width: auto;
}
.category_title_img{
	width: 24px;
	height: 24px;
}







.login{
	max-height: 146.8px;
}
.login-checkbox-label{
	display: flex;
}
.remember_forgot{
	display: flex;
}
.error{
	color: #ff0000;
}
.registration{
	max-height: 146.8px;
}
.login input{
	padding-left: 5px;
	color: $neutral_color6;
	display: flex;
	margin: 10px;
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
.registration input{
	padding-left: 5px;
	color: $neutral_color6;
	display: flex;
	margin: 10px;
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
.login h4{
	color: $secondary_color1;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
}
.registration h4{
	color: $secondary_color1;
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
}