@media (max-width: 4320px) {
  .container3 .productWrapper {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
  }
  .container3 .productWrapper .productGeneralInfo {
    border-radius: 4px;
    box-sizing: border-box;
    width: 30%;
    display: flex;
  }
  .container3 .productWrapper .productGeneralInfo .product_image {
    width: 100%;
    height: auto;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productName {
    margin: 10px;
    font-size: 25px;
    width: 1000px;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .availabilityOfProduct {
    margin: 20px 0 10px 10px;
    font-size: 15px;
    color: #000000;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productPrice {
    margin: 20px 0 10px 10px;
    font-size: 18px;
    color: #222E56;
  }
  .container3 .productWrapper .productOwnerInfo {
    width: 20%;
  }
  .container3 .productWrapper .productOwnerInfo .productOwnerImg {
    width: 80%;
    height: auto;
    margin: 10px;
  }
  .container3 .hr {
    margin: 50px 0 50px 0;
    border: 1px solid #D4D2E3;
  }
  .container3 .productDescription {
    font-size: 18px;
  }
}
@media (max-width: 1920px) {
  .container3 .productWrapper {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
  }
  .container3 .productWrapper .productGeneralInfo {
    border-radius: 4px;
    box-sizing: border-box;
    width: 30%;
    display: flex;
  }
  .container3 .productWrapper .productGeneralInfo .product_image {
    width: 100%;
    height: auto;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productName {
    margin: 10px;
    font-size: 25px;
    width: 1000px;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .availabilityOfProduct {
    margin: 20px 0 10px 10px;
    font-size: 15px;
    color: #000000;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productPrice {
    margin: 20px 0 10px 10px;
    font-size: 18px;
    color: #222E56;
  }
  .container3 .productWrapper .productOwnerInfo {
    width: 20%;
  }
  .container3 .productWrapper .productOwnerInfo .productOwnerImg {
    width: 80%;
    height: auto;
    margin: 10px;
  }
  .container3 .hr {
    margin: 50px 0 50px 0;
    border: 1px solid #D4D2E3;
  }
  .container3 .productDescription {
    font-size: 18px;
  }
}
@media (max-width: 1366px) {
  .container3 .productWrapper {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
  }
  .container3 .productWrapper .productGeneralInfo {
    border-radius: 4px;
    box-sizing: border-box;
    width: 30%;
    display: flex;
  }
  .container3 .productWrapper .productGeneralInfo .product_image {
    width: 100%;
    height: auto;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productName {
    margin: 10px;
    font-size: 25px;
    width: 1000px;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .availabilityOfProduct {
    margin: 20px 0 10px 10px;
    font-size: 15px;
    color: #000000;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productPrice {
    margin: 20px 0 10px 10px;
    font-size: 18px;
    color: #222E56;
  }
  .container3 .productWrapper .productOwnerInfo {
    width: 20%;
  }
  .container3 .productWrapper .productOwnerInfo .productOwnerImg {
    width: 80%;
    height: auto;
    margin: 10px;
  }
  .container3 .hr {
    margin: 50px 0 50px 0;
    border: 1px solid #D4D2E3;
  }
  .container3 .productDescription {
    font-size: 18px;
  }
}
@media (max-width: 1280px) {
  .container3 .productWrapper {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
  }
  .container3 .productWrapper .productGeneralInfo {
    border-radius: 4px;
    box-sizing: border-box;
    width: 30%;
    display: flex;
  }
  .container3 .productWrapper .productGeneralInfo .product_image {
    width: 100%;
    height: auto;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productName {
    margin: 10px;
    font-size: 25px;
    width: 1000px;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .availabilityOfProduct {
    margin: 20px 0 10px 10px;
    font-size: 15px;
    color: #000000;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productPrice {
    margin: 20px 0 10px 10px;
    font-size: 18px;
    color: #222E56;
  }
  .container3 .productWrapper .productOwnerInfo {
    width: 20%;
  }
  .container3 .productWrapper .productOwnerInfo .productOwnerImg {
    width: 80%;
    height: auto;
    margin: 10px;
  }
  .container3 .hr {
    margin: 50px 0 50px 0;
    border: 1px solid #D4D2E3;
  }
  .container3 .productDescription {
    font-size: 18px;
  }
}
@media (max-width: 1024px) {
  .container3 .productWrapper {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
  }
  .container3 .productWrapper .productGeneralInfo {
    border-radius: 4px;
    box-sizing: border-box;
    width: 30%;
    display: flex;
  }
  .container3 .productWrapper .productGeneralInfo .product_image {
    width: 100%;
    height: auto;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productName {
    margin: 10px;
    font-size: 25px;
    width: 1000px;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .availabilityOfProduct {
    margin: 20px 0 10px 10px;
    font-size: 15px;
    color: #000000;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productPrice {
    margin: 20px 0 10px 10px;
    font-size: 18px;
    color: #222E56;
  }
  .container3 .productWrapper .productOwnerInfo {
    width: 20%;
  }
  .container3 .productWrapper .productOwnerInfo .productOwnerImg {
    width: 80%;
    height: auto;
    margin: 10px;
  }
  .container3 .hr {
    margin: 50px 0 50px 0;
    border: 1px solid #D4D2E3;
  }
  .container3 .productDescription {
    font-size: 18px;
  }
}
@media (max-width: 720px) {
  .container3 .productWrapper {
    display: block;
    margin: 50px auto;
  }
  .container3 .productWrapper .productGeneralInfo {
    border-radius: 4px;
    box-sizing: border-box;
    width: 30%;
    display: flex;
  }
  .container3 .productWrapper .productGeneralInfo .product_image {
    width: 100%;
    height: auto;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productName {
    margin: 10px;
    font-size: 25px;
    width: 200px;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .availabilityOfProduct {
    margin: 20px 0 10px 10px;
    font-size: 15px;
    color: #000000;
  }
  .container3 .productWrapper .productGeneralInfo .priceAndName .productPrice {
    margin: 20px 0 10px 10px;
    font-size: 18px;
    color: #222E56;
  }
  .container3 .productWrapper .productOwnerInfo {
    margin-top: 40px;
    width: 60%;
  }
  .container3 .productWrapper .productOwnerInfo .productOwnerImg {
    width: 80%;
    height: auto;
    margin: 10px;
  }
  .container3 .hr {
    margin: 50px 0 50px 0;
    border: 1px solid #D4D2E3;
  }
  .container3 .productDescription {
    font-size: 18px;
  }
}/*# sourceMappingURL=product_page.css.map */