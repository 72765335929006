@media (max-width: 4096px) {
  .footer {
    width: 100%;
    background-color: #222E56;
    height: 446px;
  }
  .footer .container2 {
    max-width: 1440px;
    margin: 0 auto;
    padding: 74px 110px 0 110px;
  }
  .footer .container2 .footer_container {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_img {
    width: 244px;
    height: 53px;
    margin: 0 0 43px 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_2 {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 {
    margin: 20px 0 0 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 a {
    color: #FFFFFF;
  }
  .footer .container2 .footer_container .footer_container_2 {
    display: flex;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact {
    margin: 0 0 0 93.47px;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .footer_container .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .footer_container .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .footer_container .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .hr {
    margin: 110px 0 0 0;
    border: 1px solid #D4D2E3;
  }
  .footer .container2 .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .createdBy {
    text-align: center;
    margin: 32px 0 0 0;
    color: #D4D4D4;
  }
  .footer .container2 .createdBy a {
    color: #FFFFFF;
  }
}
@media (max-width: 2560px) {
  .footer {
    width: 100%;
    background-color: #222E56;
    height: 446px;
  }
  .footer .container2 {
    max-width: 1440px;
    margin: 0 auto;
    padding: 74px 110px 0 110px;
  }
  .footer .container2 .footer_container {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_img {
    width: 244px;
    height: 53px;
    margin: 0 0 43px 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_2 {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 {
    margin: 20px 0 0 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 a {
    color: #FFFFFF;
  }
  .footer .container2 .footer_container .footer_container_2 {
    display: flex;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact {
    margin: 0 0 0 93.47px;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .footer_container .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .footer_container .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .footer_container .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .hr {
    margin: 110px 0 0 0;
    border: 1px solid #D4D2E3;
  }
  .footer .container2 .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .createdBy {
    text-align: center;
    margin: 32px 0 0 0;
    color: #D4D4D4;
  }
  .footer .container2 .createdBy a {
    color: #FFFFFF;
  }
}
@media (max-width: 1920px) {
  .footer {
    width: 100%;
    background-color: #222E56;
    height: 446px;
  }
  .footer .container2 {
    max-width: 1440px;
    margin: 0 auto;
    padding: 74px 110px 0 110px;
  }
  .footer .container2 .footer_container {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_img {
    width: 244px;
    height: 53px;
    margin: 0 0 43px 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_2 {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 {
    margin: 20px 0 0 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 a {
    color: #FFFFFF;
  }
  .footer .container2 .footer_container .footer_container_2 {
    display: flex;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact {
    margin: 0 0 0 93.47px;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .footer_container .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .footer_container .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .footer_container .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .hr {
    margin: 110px 0 0 0;
    border: 1px solid #D4D2E3;
  }
  .footer .container2 .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .createdBy {
    text-align: center;
    margin: 32px 0 0 0;
    color: #D4D4D4;
  }
  .footer .container2 .createdBy a {
    color: #FFFFFF;
  }
}
@media (max-width: 1366px) {
  .footer {
    width: 100%;
    background-color: #222E56;
    height: 446px;
  }
  .footer .container2 {
    max-width: 1440px;
    margin: 0 auto;
    padding: 74px 110px 0 110px;
  }
  .footer .container2 .footer_container {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_img {
    width: 244px;
    height: 53px;
    margin: 0 0 43px 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_2 {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 {
    margin: 20px 0 0 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 a {
    color: #FFFFFF;
  }
  .footer .container2 .footer_container .footer_container_2 {
    display: flex;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact {
    margin: 0 0 0 93.47px;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .footer_container .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .footer_container .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .footer_container .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .hr {
    margin: 110px 0 0 0;
    border: 1px solid #D4D2E3;
  }
  .footer .container2 .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .createdBy {
    text-align: center;
    margin: 32px 0 0 0;
    color: #D4D4D4;
  }
  .footer .container2 .createdBy a {
    color: #FFFFFF;
  }
}
@media (max-width: 1280px) {
  .footer {
    width: 100%;
    background-color: #222E56;
    height: 446px;
  }
  .footer .container2 {
    max-width: 1440px;
    margin: 0 auto;
    padding: 74px 110px 0 110px;
  }
  .footer .container2 .footer_container {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_img {
    width: 244px;
    height: 53px;
    margin: 0 0 43px 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_2 {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 {
    margin: 20px 0 0 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 a {
    color: #FFFFFF;
  }
  .footer .container2 .footer_container .footer_container_2 {
    display: flex;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact {
    margin: 0 0 0 93.47px;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .footer_container .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .footer_container .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .footer_container .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .hr {
    margin: 110px 0 0 0;
    border: 1px solid #D4D2E3;
  }
  .footer .container2 .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .createdBy {
    text-align: center;
    margin: 32px 0 0 0;
    color: #D4D4D4;
  }
  .footer .container2 .createdBy a {
    color: #FFFFFF;
  }
}
@media (max-width: 1024px) {
  .footer {
    width: 100%;
    background-color: #222E56;
    height: 446px;
  }
  .footer .container2 {
    max-width: 1440px;
    margin: 0 auto;
    padding: 74px 110px 0 110px;
  }
  .footer .container2 .footer_container {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_img {
    width: 244px;
    height: 53px;
    margin: 0 0 43px 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_2 {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 {
    margin: 20px 0 0 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 a {
    color: #FFFFFF;
  }
  .footer .container2 .footer_container .footer_container_2 {
    display: flex;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact {
    margin: 0 0 0 93.47px;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .footer_container .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .footer_container .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .footer_container .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .hr {
    margin: 110px 0 0 0;
    border: 1px solid #D4D2E3;
  }
  .footer .container2 .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .createdBy {
    text-align: center;
    margin: 32px 0 0 0;
    color: #D4D4D4;
  }
  .footer .container2 .createdBy a {
    color: #FFFFFF;
  }
}
@media (max-width: 720px) {
  .footer {
    width: 100%;
    background-color: #222E56;
    height: auto;
    padding-bottom: 32px;
  }
  .footer .container2 {
    max-width: 720px;
    margin: 0 auto;
    padding: 0 auto;
  }
  .footer .container2 .footer_container {
    display: block;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_img {
    width: 244px;
    height: 53px;
    margin: 0 0 43px 0;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_2 {
    display: flex;
    justify-content: space-between;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 {
    margin: 20px auto;
  }
  .footer .container2 .footer_container .footer_container_1 .footer_container_1_3 a {
    color: #FFFFFF;
  }
  .footer .container2 .footer_container .footer_container_2 {
    display: flex;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 22px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .products .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
    margin: 0 0 18px 0;
  }
  .footer .container2 .footer_container .footer_container_2 .contact {
    margin: 0 auto;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_text {
    color: #DFC070;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 700;
  }
  .footer .container2 .footer_container .footer_container_2 .contact .footer_container_2_links {
    color: #FFFFFF;
    font-size: 18px;
    font-family: "DM Sans", sans-serif;
    display: block;
  }
  .footer .container2 .footer_container .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .footer_container .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .footer_container .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .footer_container .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .hr {
    margin: 32px 0 0 0;
    border: 1px solid #D4D2E3;
  }
  .footer .container2 .copyright {
    text-align: center;
    margin: 32px 0 0 0;
  }
  .footer .container2 .copyright .colored_copyright {
    color: #DFC070;
  }
  .footer .container2 .copyright .colored_copyright2 {
    color: #E3E3E3;
  }
  .footer .container2 .copyright .colored_copyright3 {
    color: #8B8B8B;
  }
  .footer .container2 .createdBy {
    text-align: center;
    margin: 32px auto;
    color: #D4D4D4;
  }
  .footer .container2 .createdBy a {
    color: #FFFFFF;
  }
}/*# sourceMappingURL=footer.css.map */