@import "../../css/colors";

.product_container {
  background-color: #f5f5dc; // Цвет фона контейнера товара
  border: 1px solid #ddd; // Рамка вокруг контейнера
  border-radius: 4px; // Скругление углов
  padding: 10px; // Внутренний отступ контейнера
  box-sizing: border-box; // Учитываем рамку в расчете размеров элемента
  cursor: pointer;
  
  &:hover {
    border: solid 1px black; // Изменение рамки при наведении
  }

  .product_link {
    text-decoration: none; // Убираем подчеркивание ссылки
    color: inherit; // Унаследовать цвет текста
  }

  .product_image {
    width: 100%; // Ширина изображения по ширине контейнера
    height: auto; // Автоматическая высота для сохранения пропорций
    margin-bottom: 10px; // Отступ снизу
  }

  .product_info {
    text-align: left; // Выравнивание текста внутри блока

    p {
      margin: 5px 0; // Отступы между абзацами
      color: #000; // Цвет текста

      &.product_name {
        font-weight: bold; // Жирный шрифт для названия продукта
      }

      &.product_description {
        font-size: 14px; // Размер шрифта для описания продукта
        overflow: hidden; // Скрытие переполнения текста
        text-overflow: ellipsis; // Троеточие для длинного текста
        white-space: nowrap; // Запрещает перенос текста
      }

      &.product_price {
        font-weight: bold; // Жирный шрифт для цены продукта
      }
    }
  }
}
