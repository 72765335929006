.admin_panel_window {
  min-height: 90vh;
}
.admin_panel_window .admin_panel_change_pass_text {
  margin: 10px;
}
.admin_panel_window .admin_panel_change_pass_form input {
  margin: 10px;
  padding: 3px;
  display: flex;
}
.admin_panel_window .admin_panel_change_pass_form .admin_panel_change_pass_form_button {
  padding: 5px;
  margin: 0 10px;
}/*# sourceMappingURL=changePassword.css.map */