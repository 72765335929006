.product_container {
  background-color: #f5f5dc;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.product_container:hover {
  border: solid 1px black;
}
.product_container .product_link {
  text-decoration: none;
  color: inherit;
}
.product_container .product_image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}
.product_container .product_info {
  text-align: left;
}
.product_container .product_info p {
  margin: 5px 0;
  color: #000;
}
.product_container .product_info p.product_name {
  font-weight: bold;
}
.product_container .product_info p.product_description {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.product_container .product_info p.product_price {
  font-weight: bold;
}/*# sourceMappingURL=product.css.map */