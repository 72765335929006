.sidebar {
  width: 250px;
  background-color: #A1ABCD;
  color: #ecf0f1;
  padding: 20px;
  box-sizing: border-box;
  height: 504px;
}
.sidebar .profile {
  text-align: center;
  margin-bottom: 20px;
}
.sidebar .profile .profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 10px;
}
.sidebar .profile .profile-picture:hover {
  border: solid 1px #222E56;
  cursor: pointer;
}
.sidebar .profile h2 {
  margin: 10px 0;
}
.sidebar .menu ul {
  list-style: none;
  padding: 0;
}
.sidebar .menu li {
  margin: 10px 0;
}
.sidebar .menu a {
  color: #ecf0f1;
  text-decoration: none;
  display: block;
  padding: 10px 15px;
  border-radius: 4px;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
}
.main-content h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #222E56;
}
.main-content form {
  max-width: 500px;
}
.main-content form .form-group {
  margin-bottom: 15px;
}
.main-content form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.menu a:hover,
.menu .active a {
  background-color: #222E56;
}

input[type=text],
input[type=tel],
input[type=date],
select {
  width: calc(100% - 20px);
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
}

.phone-input {
  display: flex;
}

.phone-input select {
  width: 60px;
  margin-right: 10px;
}

.gender-options {
  display: flex;
  align-items: center;
}

.gender-options input {
  margin-right: 5px;
}

.gender-options label {
  margin-right: 20px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  border: solid 1px #222E56;
  background-color: #fff;
  color: #222E56;
}

.save-button {
  background-color: #222E56;
  color: #ffffff;
}/*# sourceMappingURL=account.css.map */