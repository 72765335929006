@import "../../../css/colors";
body.active-modal {
    overflow-y: hidden;
}
@media (max-width: 4320px) {
    .modal{
        width: 100vw;
        height: 100vh;
        top: -130px;
        left: -370px;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 1;
        .overlay{
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
        }
        .modal-content {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 1.4;
            background: $primary_color3;
            padding: 0 20px;
            border-radius: 0 3px 3px 3px;
            max-width: 600px;
            min-width: 300px;
            .category_ul {
                .category_li {
                    margin: 5px;
                    color: $primary_color1;
                    cursor: pointer;
                    &:hover {
                        color: $primary_color2;
                    }
                }
            }
            .close-modal {
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 5px 7px;
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 1920px) {
    .modal{
        width: 100vw;
        height: 100vh;
        top: -10px;
        left: -370px;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 1;
        .overlay{
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
        }
        .modal-content {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 1.4;
            background: $primary_color3;
            padding: 0 20px;
            border-radius: 0 3px 3px 3px;
            max-width: 600px;
            min-width: 300px;
            .category_ul {
                .category_li {
                    margin: 5px;
                    color: $primary_color1;
                    cursor: pointer;
                    &:hover {
                        color: $primary_color2;
                    }
                }
            }
            .close-modal {
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 5px 7px;
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 1366px) {
    .modal{
        width: 100vw;
        height: 100vh;
        top: -10px;
        left: -370px;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 1;
        .overlay{
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
        }
        .modal-content {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 1.4;
            background: $primary_color3;
            padding: 0 20px;
            border-radius: 0 3px 3px 3px;
            max-width: 600px;
            min-width: 300px;
            .category_ul {
                .category_li {
                    margin: 5px;
                    color: $primary_color1;
                    cursor: pointer;
                    &:hover {
                        color: $primary_color2;
                    }
                }
            }
            .close-modal {
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 5px 7px;
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 1280px) {
    .modal{
        width: 100vw;
        height: 100vh;
        top: -10px;
        left: -370px;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 1;
        .overlay{
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
        }
        .modal-content {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 1.4;
            background: $primary_color3;
            padding: 0 20px;
            border-radius: 0 3px 3px 3px;
            max-width: 600px;
            min-width: 300px;
            .category_ul {
                .category_li {
                    margin: 5px;
                    color: $primary_color1;
                    cursor: pointer;
                    &:hover {
                        color: $primary_color2;
                    }
                }
            }
            .close-modal {
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 5px 7px;
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 1024px) {
    .modal{
        width: 100vw;
        height: 100vh;
        top: -10px;
        left: -370px;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 1;
        .overlay{
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
        }
        .modal-content {
            position: absolute;
            top: 37%;
            left: 70%;
            transform: translate(-50%, -50%);
            line-height: 1.4;
            background: $primary_color3;
            padding: 0 20px;
            border-radius: 0 3px 3px 3px;
            max-width: 600px;
            min-width: 300px;
            .category_ul {
                .category_li {
                    margin: 5px;
                    color: $primary_color1;
                    cursor: pointer;
                    &:hover {
                        color: $primary_color2;
                    }
                }
            }
            .close-modal {
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 5px 7px;
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 720px) {
    .modal{
        width: 100vw;
        height: 100vh;
        top: -10px;
        left: -370px;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 1;
        .overlay{
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
        }
        .modal-content {
            position: absolute;
            top: 33%;
            left: 140%;
            transform: translate(-50%, -50%);
            line-height: 1.4;
            background: $primary_color3;
            padding: 0 20px;
            border-radius: 0 3px 3px 3px;
            max-width: 600px;
            min-width: 300px;
            .category_ul {
                .category_li {
                    margin: 5px;
                    color: $primary_color1;
                    cursor: pointer;
                    &:hover {
                        color: $primary_color2;
                    }
                }
            }
            .close-modal {
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 5px 7px;
                cursor: pointer;
            }
        }
    }
}